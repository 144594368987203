<template>
  <b-avatar v-if="getOwner(task)" class="mr-1" size="40" variant="info">
    <b-img v-if="getOwner(task).avatar" fluid :src="getOwner(task).avatar"></b-img>
    <div v-else>{{ getOwner(task).name.substr(0, 1).toUpperCase() }}</div>
  </b-avatar>
</template>

<script>
import {
  BAvatar,
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BImg,
  },

  props: {
    task: {
      type: Object,
      required: true
    }
  },

  computed: {
    users() {
      return this.$store.state.usersConfig.users
    },
  },

  methods: {
    getOwner(task) {
      if (!task.task_activity) return task.owner
      return this.users.find(user => user.id === task.task_activity.fk_owner)
    },
  }
}
</script>