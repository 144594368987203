<template>
  <div>
    <b-avatar variant="info" size="40" :id="'avatar-' + activity.item.id" :src="activity.item.responsible.avatar">
      <b-img v-if="activity.item.responsible.avatar != null &&
        activity.item.responsible.avatar != ''
        " fluid :src="activity.item.responsible.avatar"></b-img>
      <div v-else>
        {{ activity.item.responsible.name.substr(0, 1).toUpperCase() }}
      </div>
    </b-avatar>
    <b-tooltip :target="`avatar-${activity.item.id}`">
      {{ activity.item.responsible.name }}
    </b-tooltip>
  </div>
</template>

<script>
import {
  BAvatar,
  BImg,
  BTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BImg,
    BTooltip,
  },

  props: {
    activity: {
      type: Object,
      require: true
    }
  }
}
</script>