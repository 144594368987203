<template>
  <b-dropdown no-caret v-if="getTotalOwners(activity.item).length > 0" boundary=".table-container" variant="outline">
    <template #button-content>
      <b-row v-if="getTotalOwners(activity.item).length === 1">
        <b-col>
          <b-avatar variant="primary" size="40" v-for="owner in getTotalOwners(activity.item).slice(0, 3)" :key="owner.id"
            :src="owner.avatar">
            <b-img v-if="owner.avatar != null && owner.avatar != ''" fluid :src="owner.avatar" />
            <div v-else>
              {{ owner.name ? owner.name.substr(0, 1).toUpperCase() : '' }}
            </div>
          </b-avatar>
        </b-col>
      </b-row>

      <b-row v-else-if="getTotalOwners(activity.item).length <= 3">
        <b-avatar-group variant="primary" size="40">
          <b-col>
            <b-avatar v-for="owner in getTotalOwners(activity.item).slice(0, 3)" :key="owner.id" :src="owner.avatar">
              <b-img v-if="owner.avatar != null && owner.avatar != ''" fluid :src="owner.avatar" />
              <div v-else>
                {{ owner.name ? owner.name.substr(0, 1).toUpperCase() : '' }}
              </div>
            </b-avatar>
          </b-col>
        </b-avatar-group>
      </b-row>

      <b-row v-else>
        <b-avatar-group variant="primary" size="40">
          <b-col>
            <b-avatar v-for="owner in getTotalOwners(activity.item).slice(0, 3)" :key="owner.id" :src="owner.avatar">
              <b-img v-if="owner.avatar != null && owner.avatar != ''" fluid :src="owner.avatar" />
              <div v-else>
                {{ owner.name ? owner.name.substr(0, 1).toUpperCase() : '' }}
              </div>
            </b-avatar>
            <b-avatar v-if="getTotalOwners(activity.item).length > 3"
              :text="`+${getTotalOwners(activity.item).length - 3}`" />
          </b-col>
        </b-avatar-group>
      </b-row>
    </template>

    <div style="max-height: 200px; overflow-y: auto">
      <b-dropdown-item v-for="owner in getTotalOwners(activity.item)" :key="owner.id">
        <div v-if="owner">
          <b-avatar :src="owner.avatar ? owner.avatar : null" variant="primary" size="30" class="mr-1">
            <b-img v-if="owner.avatar" fluid :src="owner.avatar" />
            <div v-else>
              {{ owner.name ? owner.name.substr(0, 1).toUpperCase() : '' }}
            </div>
          </b-avatar>
          {{ owner.name }}
        </div>
      </b-dropdown-item>
    </div>
  </b-dropdown>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BAvatarGroup,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BAvatarGroup,
    BDropdown,
    BDropdownItem,
  },

  computed: {
    users() {
      return this.$store.state.usersConfig.users
    }
  },

  props: {
    activity: {
      type: Object,
      require: true
    }
  },

  methods: {
    getUser(id) {
      return this.users.find(user => user.id === id)
    },

    getTotalOwners(activity) {
      const owners = new Map()

      activity.tasksActivities.filter(task => task.owners.length > 0)
        .forEach(task => task.owners.forEach(owner => owners.set(owner.id, this.getUser(owner.id))))


      activity.tasks.filter(task => task.owners.length > 0)
        .forEach(task => task.owners.forEach(owner => owners.set(owner.id, owner)))

      return Array.from(owners.values())
    },
  },
}
</script>