<template>
    <b-card class="ma-0 pa-0 shadow-none">
        <b-card-header>
            <b-card-title>Add or subtract {{ owner.name }} timer</b-card-title>
        </b-card-header>
        <b-col>
            <b-form-group>
                <b-form-input v-model="timer" placeholder="Example: 1h 30m 30s" />
            </b-form-group>
        </b-col>
        <b-col>
            <b-button class="mr-1" variant="primary" @click="save">Save</b-button>
            <b-button variant="outline-secondary" @click="close">Cancel</b-button>
        </b-col>
    </b-card>
</template>

<script>
import {
    BButton,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BCardHeader,
    BCardTitle,
} from 'bootstrap-vue'

export default {
    components: {
        BButton,
        BCol,
        BFormGroup,
        BFormInput,
        BCard,
        BCardHeader,
        BCardTitle
    },
    props: {
        owner: {
            type: Object,
            required: true
        },
        task: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            timer: null
        }
    },
    methods: {
        validateAndCalculateTimer(inputValue, existingTimerValue) {
            const pattern = /^([+-]?)(\d+h)?(\s*\d+m)?(\s*\d+s)?$/;
            const timeParts = pattern.exec(inputValue);

            if (timeParts) {
                let hours = 0;
                let minutes = 0;
                let seconds = 0;

                if (timeParts[2]) {
                    hours = parseInt(timeParts[2]);
                }
                if (timeParts[3]) {
                    minutes = parseInt(timeParts[3]);
                }
                if (timeParts[4]) {
                    seconds = parseInt(timeParts[4]);
                }

                const inputSeconds = hours * 3600 + minutes * 60 + seconds;

                if (timeParts[1] === '-') {
                    const newTimerValue = existingTimerValue - inputSeconds;
                    return Math.max(0, newTimerValue);
                }

                return existingTimerValue + inputSeconds;

            } else {
                return null;
            }
        },
        close() {
            this.timer = null
            this.$emit('close')
        },
        async save() {
            const type = this.task.task_template ? 'taskActivity' : 'task'

            const parsedValue = parseInt(this.owner.timer ?? 0, 10);
            const newTimerValue = this.validateAndCalculateTimer(this.timer, parsedValue);
            const data = Object.assign(this.owner, {
                timer: newTimerValue,
                fk_task: this.task.id
            });

            if (type === 'taskActivity')
                await this.$store.dispatch('updateTaskActivityOwner', data)
            else if (type === 'task')
                await this.$store.dispatch('updateTaskOwner', data)

            this.close()
        }
    }
}
</script>