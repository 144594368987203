<template>
  <b-card class="p-0 mb-0">
    <b-row>
      <b-col cols="12" lg="6" md="6" sm="12" xs="12"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0" />
      <b-col cols="12" lg="12" md="12" sm="12" xs="12">
        <div class="d-flex align-items-center justify-content-between mb-1">
          <b-col class="d-flex" cols="12" lg="4" md="2" sm="2" xs="4">
            <v-select :options="options" label="pagination" v-model="perPage" />
          </b-col>
          <div class="d-flex align-items-center justify-content-end">
            <b-col cols="12" lg="" md="12" sm="4" xs="12" style="width: 40vh;">
              <b-form-input class="" v-model="search" type="search" placeholder="Search" />
            </b-col>
            <b-button v-if="!isEmployee()" variant="outline-primary" @click="createActivityPush()">
              +Activity
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-table id="homeTable" hover :items="filterActivities" :fields="activityHeaderHome" show-empty responsive
      :per-page="perPage" :current-page="currentPage">
      <template #cell(responsible)="data">
        <ResponsibleAvatar :activity="data" />
      </template>

      <!-- Status -->
      <template #cell(status)="data">
        <div class="progress-wrapper">
          {{ Math.round(Number(calculateProgress(data.item))).toString() + '%' }}
          <b-progress :value="calculateProgress(data.item)" max="100" />
        </div>
      </template>

      <!-- MEMBERS -->
      <template #cell(members)="data">
        <MembersAvatar :activity="data" />
      </template>

      <!-- TIME -->
      <template #cell(production_time)="data">
        {{ calculateTime(data.item) }}
      </template>

      <!-- Tasks -->
      <template #row-details="data">
        <b-collapse class="mb-1 table-container mt-1" :visible="rowVisibilities[data.item.id]">
          <TasksTable :tasks="filteredTasks(data.item)" next="/" :onRowSelected="onRowSelected" @refetch="refreshData" />
        </b-collapse>
      </template>

      <!-- Button Show/Hide Tasks -->
      <template #cell(element)="data">
        <b-row class="text-center">
          <b-button class="p-0 bg-transparent" variant="outline-primary" size="sm" @click="
            rowVisibilities[data.item.id] = !rowVisibilities[data.item.id];
          data.toggleDetails();
          ">
            <feather-icon :icon="!rowVisibilities[data.item.id] ? 'ChevronDownIcon' : 'ChevronUpIcon'" />
          </b-button>
        </b-row>
      </template>
    </b-table>

    <!-- Pagination -->
    <b-row align-h="end">
      <b-col md="12" class="d-flex align-items-center justify-content-end">
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
          aria-controls="homeTable"></b-pagination>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BBadge,
  BFormInput,
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BCollapse,
  BPagination,
  BImg,
  BProgress,
  BTooltip,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { activityHeaderHome, homeTaskHeader } from '../utils/headers'
import OwnerTask from '@/components/atoms/tasks/OwnerTask.vue'
import MembersAvatar from '@/components/atoms/activity/MembersAvatar.vue'
import PriceActivity from '@/components/atoms/activity/PriceActivity.vue'
import ResponsibleAvatar from '@/components/atoms/activity/ResponsibleAvatar.vue'
import TasksTable from '@/components/molecules/tasks/TasksTable.vue';

export default {
  components: {
    BBadge,
    BPagination,
    BFormInput,
    BTable,
    BRow,
    BCol,
    BButton,
    BCard,
    vSelect,
    BImg,
    BProgress,
    BTooltip,
    BCollapse,
    VBTooltip,
    MembersAvatar,
    PriceActivity,
    ResponsibleAvatar,
    OwnerTask,
    TasksTable
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  data() {
    return {
      homeTaskHeader,
      activityHeaderHome,
      progress: 0,
      rowVisibilities: [],
      search: "",
      perPage: 10,
      currentPage: 1,
      options: [
        10, 50, 100
      ],
    };
  },

  computed: {
    activities() {
      return this.currentUser.roles[0].name === 'HEAD_OF_ORGANIZATION'
        ? this.$store.getters.getOrgActivities(this.currentUser.id)
        : this.currentUser.roles[0].name === 'HEAD_OF_DEPARTMENT'
          ? this.$store.getters.getDepartamentActivities(this.currentUser.id)
          : this.currentUser.roles[0].name === 'PROJECT_MANAGER'
            ? this.$store.getters.getProjManagerActivities(this.currentUser.id)
            : this.currentUser.roles[0].name === 'EMPLOYEE' ?
              this.$store.getters.getEmployeeActivities(this.currentUser.id)
              : this.$store.state.activityConfig.activities
    },

    filterActivities() {
      return this.activities.filter((activitie) => {
        return activitie.title.toLowerCase().includes(this.search.toLowerCase())
      })
    },

    rows() {
      return this.filterActivities.length
    },

    users() {
      return this.$store.state.usersConfig.users
    },

    currentUser() {
      return this.$store.state.usersConfig.currentUser;
    },

    tempoSalvo() {
      return this.$store.state.taskConfig.tempoSalvo
    },
  },

  async created() {
    await Promise.all([
      this.$store.dispatch('getAllTasks'),
      this.$store.dispatch('getAllUsers'),
      this.$store.dispatch('getAllActivities'),
    ])

    this.timerInit();
  },

  methods: {
    isEmployee() {
      return this.currentUser.roles[0].name === 'EMPLOYEE'
    },
    filteredTasks(item) {
      if (this.isEmployee()) {
        const employeeTasks = item.tasks.filter(task => task.owner && task.owner.id === this.currentUser.id);
        const employeeTasksActivities = item.tasksActivities.filter(task => task.task_activity.fk_owner && task.task_activity.fk_owner === this.currentUser.id)
        return [...employeeTasks, ...employeeTasksActivities]
      }
      return [...item.tasks, ...item.tasksActivities]
    },
    timerInit() {
      this.activities.forEach(activity => {
        activity.tasks.forEach(task => {
          if (task.status_timer) {
            this.$set(task, 'runningTimer', true)

            let dataAlvo = new Date(task.date_timer)
            dataAlvo = dataAlvo.setHours(dataAlvo.getHours() - 3)
            task.timer = Number(task.timer) + ((new Date() - dataAlvo) / 1000)

            if (!task.worker) {
              const worker = new Worker('../timer-worker.js');

              worker.addEventListener('message', (event) => {
                if (event.data === 'tick') {
                  if (!task.task_activity) {
                    this.$set(task, 'timer', Number(task.timer) + 1);
                  } else {
                    this.$set(task.task_activity, 'timer', Number(task.task_activity.timer) + 1);
                  }
                }
              });

              task.worker = worker;
            }

            task.worker.postMessage('start');
          }
        })

        activity.tasksActivities.forEach(task => {
          if (task.task_activity.status_timer) {
            this.$set(task, 'runningTimer', true)

            let dataAlvo = new Date(task.task_activity.date_timer)
            dataAlvo = dataAlvo.setHours(dataAlvo.getHours() - 3)
            task.task_activity.timer = Number(task.task_activity.timer) + ((new Date() - dataAlvo) / 1000)

            if (!task.worker) {
              const worker = new Worker('../timer-worker.js');

              worker.addEventListener('message', (event) => {
                if (event.data === 'tick') {
                  if (!task.task_activity) {
                    this.$set(task, 'timer', Number(task.timer) + 1);
                  } else {
                    this.$set(task.task_activity, 'timer', Number(task.task_activity.timer) + 1);
                  }
                }
              });

              task.worker = worker;
            }

            task.worker.postMessage('start');
          }
        })
      })
    },
    onRowSelected(data) {
      if (data.length) {
        const task = data[0];
        const taskType = task.task_activity ? 'task_template_activity' : 'task_activity';
        const taskId = taskType === 'task_template_activity' ? task.task_activity.id : task.id;

        this.$router.push(`/tasks/${taskId}/logs?local=${taskType}`);
      }
    },

    calculateProgress(activity) {
      const tasks = activity.tasks.concat(activity.tasksActivities.map(task => task.task_activity))
        .filter(task => task.status !== 'canceled')

      const n = 100 / tasks.length

      return tasks.reduce((p, task) => {
        if (task.status === 'approved') return p + n
        if (task.status === 'waiting_for_aproval') return p + (n / 2) + (n / 4)
        if (task.status === 'to_review') return p + n / 2
        if (task.status === 'to_do' || task.status === 'peding') return p
        return p + n / 4
      }, 0)
    },

    openCollapse(index) {
      this.$set(this.rowVisibilities, index, true);
    },

    clickEdit(row) {
      this.$router.push("/activities/edit/" + row.item.id);
    },

    clickDetails(row) {
      this.$router.push("/activities/" + row.item.id);
    },

    async refreshData() {
      await this.$store.dispatch('getAllActivities');
      this.rowVisibilities = [];
      this.timerInit();
    },

    createActivityPush() {
      this.$router.push("/activities/create");
    },

    getCollapseId(item) {
      return `collapse-${item.element}`;
    },

    getSubItems(item) {
      return this.subItems.filter(
        (subItem) => subItem.element === item.element
      );
    },

    formatTime(totalTime) {
      const segundos = Math.floor(totalTime % 60);
      const minutos = Math.floor((totalTime / 60) % 60);
      const horas = Math.floor(totalTime / 3600);
      return [horas, minutos, segundos].map(this.adicionarZero).join(':');
    },

    adicionarZero(valor) {
      return valor.toString().padStart(2, "0");
    },

    calculateTime(activity) {
      let timer = activity.tasks.reduce((a, b) => a + Number(b.timer ?? 0), 0)
      timer += activity.tasksActivities.reduce((a, b) => a + Number(b.task_activity.timer ?? 0), 0)
      return this.formatTime(timer)
    },

    deleteModal(row) {
      this.row = ''
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete that row.', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.deleteActivity(row.item.id)
          }
        })
    },

    deleteActivity(id) {
      this.$store.dispatch('deleteActivityById', id)
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.clickable-row:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.table-col {
  min-width: 100px;
}

.table-container {
  border: 1px solid #ccc;
}
</style>