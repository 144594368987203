<template>
  <div>
    {{ sumTaskPrices(activity) }}
  </div>
</template>

<script>
export default {
  props: {
    activity: {
      type: Object,
      require: true
    }
  },

  methods: {
    sumTaskPrices(activity) {
      return (
        (activity.tasksActivities || []).concat(activity.tasks || [])
          .reduce((sum, task) => sum + (parseFloat(task.price) || 0), 0)
          .toFixed(2)
      )
    }
  },
}
</script>