<template>
    <!-- <Home /> -->
    <b-row>
        <b-col>
            <b-card>
                <b-card-title>
                    <h1>Dashboard</h1>
                </b-card-title>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import Home from '@/views/Home.vue'
import {
    BCard,
    BCol,
    BRow,
    BCardTitle
} from "bootstrap-vue";

export default {
    components: {
        Home,
        BCard,
        BCol,
        BRow,
        BCardTitle
    },
}
</script>