<template>
    <div>
        <b-table selectable @row-selected="onRowSelected($event)" boundary=".table-container" responsive show-empty
            :items="tasks" :key="tasks.id" :fields="fields">
            <template #cell(duration)="data">
                <span>{{ data.item.duration }}</span>
            </template>

            <template #cell(title)="data">
                <span v-if="data.item.title">{{ data.item.title }}</span>
                <span v-else>{{ data.item.task_template.title }}</span>
            </template>

            <!-- Timer -->
            <template #cell(timer)="data">
                <b-col class="d-flex" v-if="isOwner(data.item)">
                    <span style="width: 65px; display: inline-block;">{{ formatarTempo(data.item.owners.find(o => o.id
                        === currentUser.id)) }}</span>
                    <b-button class="ml-1 border-0 p-0 bg-transparent" variant="light"
                        @click="iniciarParar(data.item, data.item.owners.find(o => o.id === currentUser.id))">
                        <feather-icon
                            :icon="data.item.owners.find(o => o.id === currentUser.id).runningTimer ? 'PauseIcon' : 'PlayIcon'" />
                    </b-button>
                </b-col>

                <b-dropdown
                    v-else-if="(currentUser.roles[0].name === 'HEAD_OF_ORGANIZATION' || currentUser.id === 1) && data.item.owners.length > 0"
                    no-caret boundary=".table-container" variant="outline">
                    <template #button-content>
                        <b-button size="sm" variant="outline-primary">
                            <feather-icon class="text-primary" icon="ClockIcon" />
                            {{ calculateTime(data.item.owners) }}
                        </b-button>
                    </template>

                    <div style="max-height: 200px; overflow-y: auto;">
                        <b-dropdown-item @click="openTimerModal(item, data.item)" v-for="item in data.item.owners"
                            :key="item.id">
                            <b-avatar :src="item.avatar" variant="primary" size="30" class="mr-1">
                                <b-img v-if="item.avatar !== null && item.avatar !== ''" fluid :src="item.avatar" />
                                <div v-else style="width: 30px; height: 30px; line-height: 30px; text-align: center;">{{
                                    item.name.substr(0, 1).toUpperCase() }}</div>
                            </b-avatar>
                            <span style="width: 65px; display: inline-block;">{{ formatarTempo(item) }}</span>
                            <b-button v-if="item.runningTimer" class="ml-1 border-0 p-0 bg-transparent" variant="light"
                                @click.stop="iniciarParar(data.item, item)">
                                <feather-icon :icon="item.runningTimer ? 'PauseIcon' : 'PlayIcon'" />
                            </b-button>
                        </b-dropdown-item>
                    </div>
                </b-dropdown>
            </template>

            <template #cell(status)="data">
                <b-badge :variant="`light-${taskCategoryStatus(data.item.status)}`">
                    {{ taskResolveStatusText(data.item.status) }}
                </b-badge>
            </template>

            <template #cell(price)="data">
                <span>{{ data.item.price }}</span>
            </template>

            <template #cell(owner)="data">
                <b-dropdown no-caret boundary=".table-container" variant="outline">
                    <template #button-content>
                        <b-row v-if="data.item.owners.length === 1">
                            <b-col>
                                <b-avatar v-for="item in data.item.owners" :key="item.id" variant="primary" size="40"
                                    :src="item.avatar">
                                    <b-img v-if="item.avatar != null && item.avatar != ''" fluid :src="item.avatar" />
                                    <div v-else>{{ item.name.substr(0, 1).toUpperCase() }}</div>
                                </b-avatar>
                            </b-col>
                        </b-row>

                        <b-row v-else-if="data.item.owners.length <= 3">
                            <b-avatar-group size="40" variant="primary">
                                <b-col>
                                    <b-avatar v-for="item in data.item.owners" :key="item.id" :src="item.avatar">
                                        <b-img v-if="item.avatar != null && item.avatar != ''" fluid :src="item.avatar" />
                                        <div v-else>{{ item.name.substr(0, 1).toUpperCase() }}</div>
                                    </b-avatar>
                                </b-col>
                            </b-avatar-group>
                        </b-row>

                        <b-row v-else>
                            <b-avatar-group size="40" variant="primary">
                                <b-col>
                                    <b-avatar v-for="i in 2" :key="i">
                                        <b-img v-if="data.item.owners[i].avatar != null" fluid
                                            :src="data.item.owners[i].avatar"></b-img>
                                        <div v-else>
                                            {{ data.item.owners[i].name.substr(0, 1).toUpperCase() }}
                                        </div>
                                    </b-avatar>
                                    <b-avatar :text="`+${data.item.owners.length - 2}`"> </b-avatar>
                                </b-col>
                            </b-avatar-group>
                        </b-row>
                    </template>

                    <div style="max-height: 200px; overflow-y: auto;">
                        <b-dropdown-item v-for="item in data.item.owners" :key="item.id">
                            <b-avatar :src="item.avatar" variant="primary" size="30" class="mr-1">
                                <b-img v-if="item.avatar !== null && item.avatar !== ''" fluid :src="item.avatar" />
                                <div v-else>{{ item.name.substr(0, 1).toUpperCase() }}</div>
                            </b-avatar>
                            {{ item.name }}
                        </b-dropdown-item>
                    </div>
                </b-dropdown>
            </template>

            <template #cell(actions)="row">
                <b-row v-if="isAllowed()">
                    <b-col cols="2">
                        <b-button class="p-0" v-b-tooltip.hover title="Edit" variant="flat" size="sm"
                            @click="clickEditTask(row)">
                            <feather-icon icon="EditIcon" />
                        </b-button>
                    </b-col>
                    <b-col cols="2">
                        <b-button class="p-0" :disabled="row.item.activity && row.item.activity.length" v-b-tooltip.hover
                            title="Delete" variant="flat" size="sm" @click="deleteTaskModal(row)">
                            <feather-icon icon="TrashIcon" />
                        </b-button>
                    </b-col>
                </b-row>
            </template>
        </b-table>

        <b-modal v-model="timerModal" title="Update timer" centered hide-footer hide-header no-close-on-backdrop>
            <TimerModal :task="currentTask" :owner="currentOwner" @close="closeTimerModal" />
        </b-modal>

    </div>
</template>

<script>
import {
    BCardTitle,
    BTable,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BImg,
    BBadge,
    VBTooltip,
    BDropdown,
    BAvatarGroup,
    BDropdownItem,
    BFormGroup,
    BFormInput
} from 'bootstrap-vue'
import { activityTasksHeaders } from '@/utils/headers'
import TimerModal from '@/components/atoms/tasks/TimerModal.vue'

export default {
    name: "TasksTable",
    components: {
        BDropdownItem,
        BAvatarGroup,
        BDropdown,
        BCardTitle,
        BTable,
        BCard,
        BButton,
        BRow,
        BCol,
        BAvatar,
        BImg,
        BBadge,
        BFormGroup,
        BFormInput,
        TimerModal
    },
    directives: {
        'b-tooltip': VBTooltip
    },
    props: {
        tasks: {
            type: Array,
            required: true
        },
        next: {
            type: String,
            required: false
        },
        onRowSelected: {
            type: Function,
            default: () => { }
        }
    },
    data() {
        return {
            fields: activityTasksHeaders,
            timerModal: false,
            currentOwner: null,
            currentTask: null
        }
    },
    computed: {
        activity() {
            return this.$store.state.activityConfig.activity;
        },
        currentUser() {
            return this.$store.state.usersConfig.currentUser;
        }
    },
    methods: {
        openTimerModal(owner, task) {
            this.timerModal = true;
            this.currentOwner = owner;
            this.currentTask = task
        },
        closeTimerModal() {
            this.timerModal = false
        },
        calculateTime(owners) {
            let timer = owners.reduce((a, b) => a + Number(b.timer ?? 0), 0)
            return this.formatTime(timer)
        },
        isAllowed() {
            return this.currentUser.id === 1 ||
                this.currentUser.id === this.activity.project.owner.id ||
                this.activity.project.departaments
                    .some(department => department.owner.id === this.currentUser.id)
        },
        deleteTaskModal(row) {
            this.row = ''
            this.$bvModal
                .msgBoxConfirm('Please confirm that you want to delete that row.', {
                    title: 'Please Confirm',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'YES',
                    cancelTitle: 'NO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(async (value) => {
                    if (value == true) {
                        if (!row.item.task_template)
                            this.$store.dispatch('deleteTask', row.item.id)
                        else
                            this.$store.dispatch('deleteTaskActivity', row.item.id)
                        this.$emit("refetch");
                    }
                })
        },
        clickEditTask(row) {
            const type = row.item.task_template ? 'taskActivity' : 'task';
            const taskId = row.item.id;
            this.$router.push(`/tasks/${taskId}/update?type=${type}&next=${this.next}`);
        },
        taskCategoryStatus(categ) {
            const statusMap = {
                to_do: 'primary',
                on_going: 'info',
                pending: 'warning',
                to_review: 'warning',
                to_correct: 'info',
                waiting_for_aproval: 'warning',
                approved: 'success',
                rejected: 'danger',
                cancelled: 'danger',
                to_upload: 'info'
            }

            return statusMap[categ] || '-'
        },
        taskResolveStatusText(categ) {
            const statusMap = {
                to_do: 'TO DO',
                on_going: 'ON GOING',
                pending: 'PENDING',
                to_review: 'TO REVIEW',
                to_correct: 'TO CORRECT',
                waiting_for_aproval: 'WAITING FOR APROVAL',
                approved: 'APPROVED',
                rejected: 'REJECTED',
                cancelled: 'CANCELED',
                to_upload: 'TO UPLOAD'
            }

            return statusMap[categ] || '-'
        },
        async iniciarParar(task, owner) {
            const stoped = task.status !== 'on_going' ? true : false;

            if (stoped && !owner.runningTimer) {
                const data = Object.assign(task, {
                    status: 'on_going'
                });

                if (!task.task_template) {
                    await this.$store.dispatch("updateTask", { ...data, owners: task.owners.map(owner => owner.id) });
                } else {
                    task.status = 'on_going'
                    data.id = task.id
                    data.fk_activity = task.fk_activity
                    data.fk_task_template = task.fk_task_template;
                    await this.$store.dispatch("updateTaskActivity", { ...data, owners: task.owners.map(owner => owner.id) });
                }
            }

            if (!owner.runningTimer) {
                this.start(owner)

                if (!task.task_template) this.$store.dispatch('editTask', { id: task.id, date_timer: new Date().toISOString(), fk_owner: owner.id })
                else this.$store.dispatch('editTaskActivity', { id: task.id, date_timer: new Date().toISOString(), fk_owner: owner.id })
                return
            }

            this.stop(owner)

            if (!task.task_template) this.$store.dispatch('editTask', { id: task.id, date_timer: new Date().toISOString(), fk_owner: owner.id })
            else this.$store.dispatch('editTaskActivity', { id: task.id, date_timer: new Date().toISOString(), fk_owner: owner.id })
        },
        start(owner) {
            this.$set(owner, 'runningTimer', true);

            if (!owner.worker) {
                const worker = new Worker('../timer-worker.js');

                worker.addEventListener('message', (event) => {
                    if (event.data === 'tick') {
                        this.$set(owner, 'timer', Number(owner.timer) + 1);
                    }
                });

                owner.worker = worker;
            }

            owner.worker.postMessage('start');
        },
        stop(owner) {
            if (owner.worker) {
                owner.worker.postMessage('stop');
                owner.worker.terminate();
                this.$set(owner, 'worker', null);
            }

            this.$set(owner, 'runningTimer', false);
        },
        cleanup() {
            this.tasks.forEach((task) => {
                task.owners.forEach((owner) => {
                    this.stop(owner);
                });
            });
        },
        formatTime(totalTime) {
            const segundos = Math.floor(totalTime % 60);
            const minutos = Math.floor((totalTime / 60) % 60);
            const horas = Math.floor(totalTime / 3600);
            return [horas, minutos, segundos].map(this.adicionarZero).join(':');
        },
        formatarTempo(owner) {
            const tempoAtual = owner.timer
            return this.formatTime(tempoAtual)
        },
        adicionarZero(valor) {
            return valor.toString().padStart(2, "0");
        },
        isOwner(item) {
            return item.owners.some(owner => owner.id === this.currentUser.id) ? true : false;
        },
    },
    beforeDestroy() {
        this.cleanup();
    },
}
</script>